import ibanData from '../baseDatasets/iban.json';

const ibanMask = (value) => {
  if (!value || value.length < 2) {
    return [
      /\w/,
      /\w/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
  }

  const country = value.substring(0, 2);

  if (!ibanData[country]) {
    return [
      /\w/,
      /\w/,
      /\d/,
      /\d/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      ' ',
      /\w/,
      /\w/
    ];
  }

  const completeGroups = Math.floor(ibanData[country].length / 4);
  const additionalChars = ibanData[country].length - completeGroups * 4;
  let regex = [
    /\w/,
    /\w/,
    /\d/,
    /\d/
  ];
  for (let i = 1; i < completeGroups; i++) {
    regex = regex.concat([
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
    ]);
  }
  if (additionalChars > 0) {
    regex.push(' ');
  }
  for (let i = 0; i < additionalChars; i++) {
    regex.push(/\w/);
  }
  return regex;
}

export default ibanMask;
