import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ApiClient from '../../utils/ApiClient';
import AppHeader from '../../components/AppHeader/AppHeader';
import ApplicationForm from '../../components/ApplicationForm/ApplicationForm';
import AppFooter from '../../components/AppFooter/AppFooter';
import { useTranslation } from 'react-i18next';

function FormPage() {
  const { formCode } = useParams();
  const { state } = useLocation();
  const [ formData, setFormData ] = useState(
    (state || {}).formData
  );
  const [ fetchingFormData, setFetchingFormData ] = useState(true);
  const [ formSuccessfullySubmitted, setFormSuccessfullySubmitted ] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Use Mount Hook to fetch Form Data
  useEffect(() => {
    const getFormData = async () => {
      setFetchingFormData(true);
      try {
        const response = await ApiClient.get(`/forms/${formCode}`);
        setFormData(response.data);
        setFetchingFormData(false);
      } catch (e) {
        if (e.isAxiosError) {
          console.log(e)
          if (!e.response) {    // Backend nicht verfügbar
            navigate('/', {
              state: {
                error: 'ERROR_BACKEND_NOT_AVAILABLE',
                formCode
              },
              replace: true
            });
          } else if (e.response.data.statusCode === 404 && e.response.data.message === 'FORM_NOT_FOUND') {
            navigate('/', {
              state: {
                error: 'ERROR_FORM_NOT_FOUND',
                formCode
              },
              replace: true
            });
          } else {
            navigate('/', {
              state: {
                error: 'ERROR_FORM_DATA_FETCH_ERROR',
                formCode
              },
              replace: true
            });
          }
        } else {
          navigate('/', {
            state: {
              error: 'ERROR_UNDEFINED_REQUEST',
              formCode
            },
            replace: true
          });
        }
      }
    };

    if (!formData) {
      getFormData();
    } else {
      setFetchingFormData(false);
    }
  }, []);

  if (!i18n.isInitialized) {
    return (
      <div>
        <AppHeader />
        <div className="container text-center">
          <h3>
            LOADING APPLICATION
          </h3>
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (formSuccessfullySubmitted) {
    //setFormData(null);

    return (
      <div>
        <AppHeader />
        <div className="container text-center">
          <h3>
            { t('application_form.success.submitted_successfully') }
          </h3>
          <p>
            { t('application_form.success.confirmation_email') }
          </p>
          <p>
            { t('application_form.success.close_page') }
          </p>
          <div className="mt-5">
            <AppFooter />
          </div>
        </div>
      </div>
    );
  }

  if (fetchingFormData) {
    return (
      <div>
        <AppHeader />
        <div className="container text-center">
          <h3>
            { t('application_form.fetching_form_data').toUpperCase() }
          </h3>
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <AppHeader />

      <ApplicationForm formData={formData} setSuccessfullySubmitted={setFormSuccessfullySubmitted} />

    </div>
  );
}

export default FormPage;
