function LogDetail({ title, value }) {
  return (
    <div className="mb-2">
      <div><small><b>{ title }</b></small></div>
      <div>{ value }</div>
    </div>
  );
}

export default LogDetail;
