import { useTranslation } from 'react-i18next';
import './SumFooter.scss';

function SumFooter({ payback, currency }) {
  const { t } = useTranslation();
  const CurrencyFormatter = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency
  });

  return (
    <div className="sum-footer">
      <div className="container">
        <h3>{ t('application_form.footer.expected_reimbursement', { value: CurrencyFormatter.format(payback) }) }</h3>
        <small>{ t('application_form.footer.hint_doc_upload') }</small>
      </div>
    </div>
  );
}

export default SumFooter;
