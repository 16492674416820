function EnhancedSelect({ id, label, name, onChange, value, error, touched, requiredField = false, children, ...rest }) {
  let validationClass = null;

  if (touched && error) {
    validationClass = 'is-invalid';
  } else if (touched && !error) {
    validationClass = 'is-valid';
  }

  return (
    <>
      <label htmlFor={ id } className="form-label">{ requiredField ? `${label}*` : label }</label>
      <select id={ id } className={ [ 'form-select', validationClass ].join(' ') } name={ name } value={ value }
              onChange={ onChange } {...rest}>
        {
          children
        }
      </select>
      {
        error ? (
          <div className="invalid-feedback">
            { error }
          </div>
        ) : null
      }
    </>
  );
}

export default EnhancedSelect;
