import { useLocation } from 'react-router-dom';
import AppHeader from '../../components/AppHeader/AppHeader';
import { useTranslation } from 'react-i18next';
import AppFooter from '../../components/AppFooter/AppFooter';
import { Formik } from 'formik';
import TextInput from '../../components/TextInput';
import Textarea from '../../components/Textarea';
import ApiClient from '../../utils/ApiClient';
import { useState } from 'react';
import SupportFormValidationSchema from '../../utils/SupportFormValidationSchema';

function ContactUsPage() {
  const { state } = useLocation();
  const { t, i18n } = useTranslation();

  const [ submissionError, setSubmissionError ] = useState(null);
  const [ successfullySubmitted, setSuccessfullySubmitted ] = useState(false);

  console.log(state);

  if (!i18n.isInitialized) {
    return <div>Loading Application ...</div>
  }

  if (successfullySubmitted) {
    return (
      <div>
        <AppHeader />
        <div className="container">
          <h1>{ t('contact_us_page.title') }</h1>
          <div className="alert alert-success" role="alert">
            { t('contact_us_page.form_submitted') }
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }


  return (
    <div>
      <AppHeader />
      <div className="container">
        <h1>{ t('contact_us_page.title') }</h1>
        <Formik
          initialValues={{
          firstName: state.formValues?.firstName || '',
          lastName: state.formValues?.lastName || '',
          email: state.formValues?.email || '',
          phone: '',
          subject: '',
          message: '',
          formHash: state.hash,
          formCode: state.formCode,
          errorReason: state.errorReason,
          formValues: state.formValues,
          errorData: JSON.stringify({ errorRaw: state.errorRaw, errorMessage: state.errorMessage }),
        }}
          validationSchema={SupportFormValidationSchema}
          onSubmit={ async (values, { setSubmitting }) => {
          setSubmissionError(null);

          try {
            const response = await ApiClient.post(`/support/contact/email`, values);
            setSuccessfullySubmitted(true);
          } catch (err) {
            console.error(err);
            setSubmissionError({ message: t('error.ERROR_FORM_SUPPORT_SUBMIT_FAILED') });
          } finally {
            setSubmitting(false);
          }
        } }>
          {
            ({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setValues
            }) => (
              <form onSubmit={ handleSubmit }>
                {
                  submissionError ? (
                    <div className="alert alert-danger" role="alert">
                      {
                        submissionError?.message ? <span>{ submissionError.message }</span> : null
                      }
                      {
                        submissionError?.details ? <><br /><span><small>{ submissionError.details }</small></span></> : null
                      }
                    </div>
                  ) : null
                }

                <div className="row g-3 mb-5">
                  <div className="col-md-6">
                    <TextInput
                      label={ t('contact_us_page.contactForm.firstName') }
                      name="firstName"
                      id="inputFirstName"
                      requiredField={ true }
                      error={ errors.firstName }
                      touched={ touched.firstName }
                      onBlur={ handleBlur }
                      onChange={ handleChange }
                      value={ values.firstName }/>
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      label={ t('contact_us_page.contactForm.lastName') }
                      name="lastName"
                      id="inputLastName"
                      requiredField={ true }
                      onBlur={ handleBlur }
                      error={ errors.lastName }
                      touched={ touched.lastName }
                      onChange={ handleChange }
                      value={ values.lastName }/>
                  </div>

                  <div className="col-md-6">
                    <TextInput
                      label={ t('contact_us_page.contactForm.email') }
                      name="email"
                      id="inputEmail"
                      typeOverwrite="email"
                      requiredField={ true }
                      error={ errors.email }
                      touched={ touched.email }
                      onBlur={ handleBlur }
                      onChange={ handleChange }
                      value={ values.email } />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      label={ t('contact_us_page.contactForm.phone') }
                      name="phone"
                      id="inputPhone"
                      typeOverwrite="tel"
                      onBlur={ handleBlur }
                      error={ errors.phone }
                      touched={ touched.phone }
                      onChange={ handleChange }
                      value={ values.phone }/>
                  </div>
                  <div className="col-md-12">
                    <Textarea
                      label={ t('contact_us_page.contactForm.message') }
                      name="message"
                      id="inputMessage"
                      rows={6}
                      requiredField={ true }
                      onBlur={ handleBlur }
                      error={ errors.message }
                      touched={ touched.message }
                      onChange={ handleChange }
                      value={ values.message }/>
                  </div>
                </div>
                <button type="submit" className="btn btn-dark" disabled={ isSubmitting }>
                  { t('contact_us_page.contactForm.submit') }
                </button>
              </form>
            )
          }
        </Formik>
      </div>
      <AppFooter />
    </div>
  );
}

export default ContactUsPage;
