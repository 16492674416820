import axios from 'axios';
import { API_BASE_URI } from '../appConfig';

const client = axios.create({
  baseURL: API_BASE_URI
  //baseURL: 'http://localhost:8081'
  //baseURL: 'https://red7api.herokuapp.com'
});

export default client;
