import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Alert from '../../components/Alert/Alert';
import ApiClient from '../../utils/ApiClient';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

function HomePage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [ formCode, setFormCode ] = useState(
    state && state.formCode ? state.formCode : ''
  );
  const [ formData, setFormData ] = useState(null);
  const [ fetchingFormData, setFetchingFormData ] = useState(false);
  const [ error, setError ] = useState(
    state && state.error ? state.error : null
  );

  const { instance, accounts } = useMsal();

  /*if (accounts.length > 0) {
    console.log(accounts[0]);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      console.log(response)
    })
  }*/

  const fetchFormData = async () => {
    setFetchingFormData(true);
    try {
      const response = await ApiClient.get(`/forms/${formCode}`);
      navigate(`/${formCode}`, {
        state: {
          formData: response.data
        },
        replace: true
      });
    } catch (err) {
      if (err.isAxiosError) {
        console.log(err);
        if (!err.response) {
          setError('ERROR_BACKEND_NOT_AVAILABLE2');
        } else {
          setError('ERROR_FORM_NOT_FOUND');
        }
      }
      setFetchingFormData(false);
    }
  };

  return (
    <div>
      <div className="position-absolute top-0 start-0 d-flex justify-content-center align-items-center h-100 w-100">
        <div className="curtain-darker p-3 text-white">
          <h2 className="text-uppercase">{t('redseven')} {t('expense_reimbursement')}</h2>
          <h4>{t('home_page.salutation')}</h4>
          <p>
            {t('home_page.intro')}
          </p>
          <p>
            <Trans i18nKey="home_page.explanation" components={{ br: <br /> }} />
          </p>
          {
            // ERROR MESSAGE
            error ? <Alert type="danger">
              {
                t(`error.${error}`)
              }
            </Alert>: null
          }
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Form-Code"
                   aria-label="Formular Code" aria-describedby="button-addon"  value={formCode} onChange={(evt) => setFormCode(evt.target.value)} onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                navigate(`/${formCode}`, { replace: true });
              }
            }} />
            <button className="btn btn-danger" type="button" id="button-addon" disabled={fetchingFormData} onClick={fetchFormData}>
              {
                fetchingFormData ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    &nbsp;{t('loading')} ...
                  </>
                ) : t('next')
              }
            </button>
          </div>
          <div className="mt-3 text-center">
            <small>{t('footer.address')}</small><br/>
            <small>
              <Link to={'/privacy'} role="button" className="text-decoration-none text-white">{t('footer.data_protection_terms')}</Link>&nbsp;·&nbsp;
              <a href="https://www.redseven.de/impressum" className="text-decoration-none text-white">{t('footer.imprint')}</a>&nbsp;·&nbsp;
              <Link to={'/login'} role="button" className="text-decoration-none text-white">Mitarbeiterbereich</Link>
            </small>
          </div>
        </div>
      </div>
      <div className="bg-image-cover"></div>
    </div>
  );
}

export default HomePage;