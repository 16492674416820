function EmailLogEntryTypePill({ logType }) {
  switch (logType) {
    case 'ERROR':
      return <span className="badge rounded-pill bg-danger">FEHLER</span>;
    case 'SUCCESS':
      return <span className="badge rounded-pill bg-success">OK</span>;
    default:
      return <span className="badge rounded-pill bg-primary">{ logType }</span>;
  }

}

export default EmailLogEntryTypePill;
