import AppNav from '../../components/AppNav/AppNav';
import AppFooter from '../../components/AppFooter/AppFooter';
import { Trans, useTranslation } from 'react-i18next';

function PrivacyTermsPage() {
  const { t, i18n } = useTranslation();

  if (!i18n.isInitialized) {
    return <div>Loading Application ...</div>
  }

  return (
    <div>
      <AppNav />
      <Trans i18nKey="privacy_terms" components={{
        div: <div />,
        p: <p />,
        h1: <h1 />,
        h3: <h3 />,
        h5: <h5 />,
        table: <table />,
        thead: <thead />,
        tbody: <tbody />,
        tr: <tr />,
        th: <th />,
        td: <td />,
        ul: <ul />,
        li: <li />,
        small: <small />
      }} />
      <AppFooter />
    </div>
  );
}

export default PrivacyTermsPage;
