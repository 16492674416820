import { useLocation } from 'react-router-dom';
import AppNav from '../../components/AppNav/AppNav';
import LogDetail from '../../components/LogDetail';
import EmailLogEntryTypePill from '../../components/EmailLogEntryTypePill';
import moment from 'moment-timezone';

function EmailLogDetailPage() {
  const { state } = useLocation();

  console.log(state)

  if (!state.logEntry) {
    return (
      <div>
        <AppNav />
        <div className="container">
          <h1>E-Mail Log Detail</h1>
          Kein Log-Entry übergeben
        </div>
      </div>
    );
  }

  const logEntry = state.logEntry;

  return (
    <div>
      <AppNav />
      <div className="container">
        <h1>E-Mail Log Detail</h1>
        <h5 className="text-muted">LOG ID: {logEntry.id}</h5>

        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-black">E-Mail Details</h5>
                <LogDetail title="ID" value={logEntry.id} />
                <LogDetail title="Status" value={<EmailLogEntryTypePill logType={logEntry.logType} />} />
                <LogDetail title="Message ID" value={logEntry.messageId} />
                <LogDetail title="DSN ID" value={logEntry.dsnId || 'n/a'} />
                <LogDetail title="Sendezeitpunkt" value={logEntry.appTimestamp ? moment.utc(logEntry.appTimestamp).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm:ss') : 'n/a'} />
                <LogDetail title="Absender" value={logEntry.from} />
                <LogDetail title="Empfänger" value={logEntry.recipient} />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title text-black">Betreff</h5>
                { logEntry.subject }
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-black">E-Mail Body</h5>
                <pre>
                  { logEntry.body }
                </pre>
              </div>
            </div>

            {
              logEntry.error ? (
                <div className="card mt-4">
                  <div className="card-body">
                    <h5 className="card-title text-black">Fehlermeldung</h5>
                    { logEntry.error }
                  </div>
                </div>
              ) : null
            }

            <div className="card mt-4">
              <div className="card-body">
                <h5 className="card-title text-black">Technische Information</h5>
                <code>
                  {
                    JSON.stringify(JSON.parse(logEntry.mailInfo), null, 2)
                  }
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailLogDetailPage;
