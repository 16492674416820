import { useState } from 'react';
import { Formik } from 'formik';
import { Uploader, Tooltip, Whisper } from 'rsuite';
import SumFooter from '../SumFooter/SumFooter';
import TextInput from '../TextInput';
import EnhancedSelect from '../EnhancedSelect';
import MaskedTextInput from '../MaskedTextInput';
import AppFooter from '../AppFooter/AppFooter';
import ibanMask from '../../utils/IbanMask';
import sortedCountries from '../../utils/SortedCountries';
import ApplicationFormValidationSchema from '../../utils/ApplicationFormValidationSchema';
import ApiClient from '../../utils/ApiClient';
import CheckBox from '../CheckBox';
import AllowedUploadFileFormats from '../../utils/AllowedUploadFileFormats';
import DateInput from '../DateInput';
import { Trans, useTranslation } from 'react-i18next';
import { API_BASE_URI } from '../../appConfig';
import InputGroup from '../InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function ApplicationForm({ formData, setSuccessfullySubmitted }) {

  const {
    email,
    episodeNo,
    currency,
    firstName,
    formCode,
    formType,
    id,
    lastName,
    mileageAllowance,
    orderNumber,
    production,
    travelReimbursementType,
    reimbursementTypeParticipation,
    reimbursementParticipationFlatRate,
    reimbursementParticipationPerDay,
    reimbursementParticipationDays,

    predefinedReimbursement,
    predefinedReimbursementValue,

    validFrom,
    validTo,
    updatedAt,
    createdAt,
  } = formData;

  const { t } = useTranslation();

  const [ submissionError, setSubmissionError ] = useState(null);

  const tooltip = (
    <Tooltip>
      { t('application_form.sections.additional_expanses.info_notes.medical') }
    </Tooltip>
  );

  return (
    <Formik
      initialValues={ {
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        address: '',
        zip: '',
        city: '',
        country: 'DE',

        email: email ? email : '',
        emailConfirmation: email ? email : '',

        bankAccountOwner: '',
        iban: '',
        bankInstitute: '',
        swift: '',

        showNumber: episodeNo ? episodeNo : '',
        performancePeriodFrom: '',
        performancePeriodTo: '',
        currency: currency || 'EUR',

        attendance: reimbursementTypeParticipation,
        attendanceDaysCount: reimbursementParticipationDays || '',
        attendanceDayValue: reimbursementParticipationPerDay || '',
        attendanceFlatRateSum: reimbursementParticipationFlatRate || '',

        travelExpensesFlatRate: false,
        travelExpensesFlatValue: 0,
        travelExpensesDistanceBased: false,
        travelExpensesDistance: 0,
        travelExpensesDistanceKmValue: mileageAllowance ? mileageAllowance : 0.0,
        travelExpensesAdditional: false,
        travelExpensesAdditionalValue: 0,

        additionalExpensesMed: false,
        additionalExpensesMedValue: 0,
        additionalExpensesPostage: false,
        additionalExpensesPostageValue: 0,
        additionalExpensesCatering: false,
        additionalExpensesCateringVal: 0,
        additionalExpensesOutfit: false,
        additionalExpensesOutfitValue: 0,
        additionalExpensesWin: false,
        additionalExpensesWinValue: 0,

        addExpensesOthExp: '',
        addExpensesOthExpVal: 0,

        predefinedReimbursement: predefinedReimbursement,
        predefinedReimbursementValue: predefinedReimbursementValue,

        files: [],

        correctDataConfirm: false,
        docsUplConfirm: false,
        privacyConfirm: null
      } }
      validationSchema={ ApplicationFormValidationSchema }
      onSubmit={ async (values, { setSubmitting }) => {
        setSubmissionError(null);

        try {
          const response = await ApiClient.post(`/forms/${formCode}`, values);
          setSuccessfullySubmitted(true);
        } catch (err) {
          console.log(err);
          console.log(err.response);
          if (err?.response?.data?.message) {
            try {
              const msg = JSON.parse(err.response.data.message);
              setSubmissionError({ message: t('error.ERROR_FORM_SUBMIT_FAILED'), details: <Trans i18nKey="error.ERROR_FORM_SUBMIT_FAILED_DETAIL" components={{ Link: <Link to="/support/contact-us" state={{ formCode: formCode, hash: msg.hash, formValues: values, errorReason: 'ERROR_FORM_SUBMIT_FAILED', errorRaw: err?.response?.data, errorMessage: msg }} /> }} /> });
            } catch (e) {
              setSubmissionError({ message: t('error.ERROR_FORM_SUBMIT_FAILED'), details: <Trans i18nKey="error.ERROR_FORM_SUBMIT_FAILED_DETAIL" components={{ Link: <Link to="/support/contact-us" state={{ formCode: formCode, hash: '', formValues: values, errorReason: 'ERROR_FORM_SUBMIT_FAILED', errorRaw: err?.response?.data }} /> }} /> });
            }
          } else {
            setSubmissionError({ message: t('error.ERROR_FORM_SUBMIT_FAILED'), details: <Trans i18nKey="error.ERROR_FORM_SUBMIT_FAILED_DETAIL" components={{ Link: <Link to="/support/contact-us" state={{ formCode: formCode, hash: '', formValues: values, errorReason: 'ERROR_FORM_SUBMIT_FAILED', errorRaw: err?.response?.data }} /> }} /> });
          }
        } finally {
          setSubmitting(false);
        }
      } }
    >
      {
        ({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
           setValues
         }) => {

          // region ExpectedPayback Calculation
          let expectedPayback =
            (values.travelExpensesAdditional ? values.travelExpensesAdditionalValue : 0) +
            (values.additionalExpensesMed ? values.additionalExpensesMedValue : 0) +
            (values.additionalExpensesPostage ? values.additionalExpensesPostageValue : 0) +
            (values.additionalExpensesCatering ? values.additionalExpensesCateringVal : 0) +
            (values.additionalExpensesOutfit ? values.additionalExpensesOutfitValue : 0) +
            (values.additionalExpensesWin ? values.additionalExpensesWinValue : 0) +
            (values.addExpensesOthExp ? values.addExpensesOthExpVal : 0) +
            (values.predefinedReimbursementValue ? values.predefinedReimbursementValue : 0);

          if (values.attendance === 'participationFixedRate') {
            expectedPayback += values.attendanceFlatRateSum || 0;
          } else {
            expectedPayback += values.attendanceDaysCount * values.attendanceDayValue;
          }

          if (values.travelExpensesDistanceBased) {
            expectedPayback += values.travelExpensesDistance * values.travelExpensesDistanceKmValue;
          }

          if (values.travelExpensesFlatRate) {
            expectedPayback += values.travelExpensesFlatValue;
          }
          //endregion

          return (
            <>
              <div className="container">
                <form onSubmit={ handleSubmit }>
                  {
                    // TITEL UND EINLEITUNG
                  }
                  <section>
                    <h1>{ t('expense_reimbursement') }</h1>
                    <p>
                      <Trans i18nKey="application_form.into_text" values={{ production: production }} />
                    </p>
                    <p>
                      <Trans i18nKey="application_form.top_info_text" />
                    </p>
                  </section>

                  {
                    // ANGABEN ZUM KANDIDATEN
                  }
                  <section className="mb-5">
                    <h4>{ t('application_form.sections.candidate_information.title') }</h4>
                    <div className="row g-3 mb-5">
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.firstName') }
                          name="firstName"
                          id="inputFirstName"
                          placeholder="Anna"
                          requiredField={ true }
                          error={ errors.firstName }
                          touched={ touched.firstName }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.firstName }/>
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.lastName') }
                          name="lastName"
                          id="inputLastName"
                          placeholder="Müller"
                          requiredField={ true }
                          onBlur={ handleBlur }
                          error={ errors.lastName }
                          touched={ touched.lastName }
                          onChange={ handleChange }
                          value={ values.lastName }/>
                      </div>
                      <div className="col-12">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.address') }
                          name="address"
                          id="inputAddress"
                          requiredField={ true }
                          error={ errors.address }
                          touched={ touched.address }
                          placeholder="Hauptstraße 10"
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.address }/>
                      </div>
                      <div className="col-md-3">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.zip') }
                          name="zip"
                          id="inputZIP"
                          placeholder="10115"
                          requiredField={ true }
                          error={ errors.zip }
                          touched={ touched.zip }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.zip }/>
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.city') }
                          name="city"
                          id="inputCity"
                          placeholder="Berlin"
                          requiredField={ true }
                          error={ errors.city }
                          touched={ touched.city }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.city }/>
                      </div>
                      <div className="col-md-3">
                        <EnhancedSelect
                          label={ t('application_form.sections.candidate_information.country') }
                          name="country"
                          id="selectCountry"
                          requiredField={ true }
                          error={ errors.country }
                          touched={ touched.country }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.country }
                        >
                          <option value="" disabled></option>
                          {
                            sortedCountries.map(([ countryCode, country ], i) => (
                              <option value={ countryCode } key={ countryCode }>{ country }</option>
                            ))
                          }
                        </EnhancedSelect>
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.email') }
                          name="email"
                          id="inputEmail"
                          typeOverwrite="email"
                          requiredField={ true }
                          error={ errors.email }
                          touched={ touched.email }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.email } />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.email_confirmation') }
                          name="emailConfirmation"
                          id="inputEmailConfirmation"
                          typeOverwrite="email"
                          autocomplete="off"
                          requiredField={ true }
                          error={ errors.emailConfirmation }
                          touched={ touched.emailConfirmation }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.emailConfirmation } />
                      </div>
                    </div>

                    <div className="row g-3">
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.account_holder') }
                          name="bankAccountOwner"
                          id="inputBankAccountOwner"
                          requiredField={ true }
                          error={ errors.bankAccountOwner }
                          touched={ touched.bankAccountOwner }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.bankAccountOwner }/>
                      </div>
                      <div className="col-md-6">
                        <MaskedTextInput
                          label={ t('application_form.sections.candidate_information.iban') }
                          name="iban"
                          id="inputIBAN"
                          requiredField={ true }
                          error={ errors.iban }
                          touched={ touched.iban }
                          onBlur={ handleBlur }
                          onChange={ (evt) => {
                            evt.target.value = evt.target.value.trim().toUpperCase();
                            handleChange(evt);
                          } }
                          value={ values.iban }
                          mask={ ibanMask }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.bank_name') }
                          name="bankInstitute"
                          id="inputBankInstitute"
                          placeholder="optional"
                          error={ errors.bankInstitute }
                          touched={ touched.bankInstitute }
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          value={ values.bankInstitute }/>
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={ t('application_form.sections.candidate_information.swift') }
                          name="swift"
                          id="inputSWIFT"
                          placeholder={ values.iban && values.iban.startsWith('DE') ? 'optional' : null }
                          requiredField={ !values.iban || !values.iban.startsWith('DE') }
                          maxLength={ 11 }
                          error={ errors.swift }
                          touched={ touched.swift }
                          onBlur={ handleBlur }
                          onChange={ (evt) => {
                            evt.target.value = evt.target.value.toUpperCase();
                            handleChange(evt);
                          } }
                          value={ values.swift }/>
                      </div>
                    </div>
                  </section>

                  {
                    // AUFWANDSENTSCHÄDIGUNG / KOSTENERSTATTUNG
                  }
                  <section className="mb-5">
                    <h4>{ t('application_form.sections.reimbursement.title') }</h4>

                    <div className="row g-3 mb-3">
                      {
                        values.showNumber ? (
                          <>
                            <div className="col-md-6">
                              <TextInput
                                label={ t('application_form.sections.reimbursement.episode_no') }
                                name="showNumber"
                                id="inputShowNumber"
                                disabled={true}
                                error={ errors.showNumber }
                                touched={ touched.showNumber }
                                onBlur={ handleBlur }
                                onChange={ handleChange }
                                value={ values.showNumber }/>
                            </div>
                            <div className="col-md-6">
                              {
                                // SPACER
                              }
                            </div>
                          </>
                        ) : null
                      }
                      <div className="col-md-4">
                        <DateInput
                          id="inputPerformancePeriodFrom"
                          name="performancePeriodFrom"
                          label={ t('application_form.sections.reimbursement.performance_period_from') }
                          requiredField={ true }
                          onChange={ handleChange }
                          value={ values.performancePeriodFrom }
                          error={ errors.performancePeriodFrom }
                          touched={ touched.performancePeriodFrom }
                          onBlur={ handleBlur } />
                      </div>
                      <div className="col-md-4">
                        <DateInput
                          id="inputPerformancePeriodTo"
                          name="performancePeriodTo"
                          label={ t('application_form.sections.reimbursement.performance_period_to') }
                          onChange={ handleChange }
                          value={ values.performancePeriodTo }
                          error={ errors.performancePeriodTo }
                          touched={ touched.performancePeriodTo }
                          onBlur={ handleBlur } />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="selectCurrency" className="form-label">{ t('application_form.sections.reimbursement.currency') }</label>
                        <select id="selectCurrency" className="form-select" name="currency" onChange={ handleChange }
                                value={ values.currency } disabled={!!currency} >
                          <option value="" disabled>{ t('application_form.sections.reimbursement.select_currency') }</option>
                          <option value="EUR">{ t('application_form.sections.reimbursement.currencies.eur') }</option>
                          <option value="USD">{ t('application_form.sections.reimbursement.currencies.usd') }</option>
                          <option value="GBP">{ t('application_form.sections.reimbursement.currencies.gbp') }</option>
                          <option value="CHF">{ t('application_form.sections.reimbursement.currencies.chf') }</option>
                        </select>
                      </div>
                    </div>

                    {
                      formData.reimbursementTypeParticipation !== 'noParticipation' ? (
                        <section>
                          <h5>{ t('application_form.sections.attendance.title') }</h5>

                          <div className="row g-3">
                            <div className="col-12">
                              <select id="selectAttendance" className="form-select" name="attendance"
                                      onChange={ handleChange }
                                      value={ values.attendance } disabled={ true } >
                                <option value="participationFixedRate">{ t('application_form.sections.attendance.attendance_type.lump_sum') }</option>
                                <option value="participationDayBased">{ t('application_form.sections.attendance.attendance_type.daily_base') }</option>
                              </select>
                            </div>
                            {
                              values.attendance === 'participationDayBased' ?
                                (
                                  <>
                                    <div className="col-md-6">
                                      <label htmlFor="inputAttendanceDays" className="form-label">{ t('application_form.sections.attendance.daily_base.days') }</label>
                                      <input type="number" className="form-control" id="inputAttendanceDays" min="0" step="1"
                                             name="attendanceDaysCount" onChange={ handleChange }
                                             value={ values.attendanceDaysCount } disabled={!!reimbursementParticipationDays} />
                                    </div>
                                    <div className="col-md-6">
                                      <label htmlFor="inputAttendanceDayValue" className="form-label">{ t('application_form.sections.attendance.daily_base.amount_per_day') }</label>
                                      <div className="input-group mb-3">
                                        <input type="number" className="form-control" id="inputAttendanceDayValue"
                                               placeholder="0,00"
                                               min="0" step="0.01"
                                               ariaDescribedby="currency-addon1" name="attendanceDayValue"
                                               onChange={ handleChange }
                                               value={ values.attendanceDayValue }
                                               disabled={!!reimbursementParticipationPerDay}/>
                                        <span className="input-group-text" id="currency-addon1">{ values.currency }</span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="col-12">
                                    <label htmlFor="inputAttendanceFlatRateSum" className="form-label">{ t('application_form.sections.attendance.daily_base.sum') }</label>
                                    <div className="input-group mb-3">
                                      <input type="number" className="form-control" id="inputAttendanceFlatRateSum"
                                             placeholder="0,00"
                                             min="0" step="0.01"
                                             ariaDescribedby="currency-addon2" name="attendanceFlatRateSum"
                                             onChange={ handleChange }
                                             value={ values.attendanceFlatRateSum }
                                             disabled={!!reimbursementParticipationFlatRate}/>
                                      <span className="input-group-text" id="currency-addon2">{ values.currency }</span>
                                    </div>
                                  </div>
                                )

                            }
                          </div>
                        </section>
                      ) : null
                    }

                    {
                      travelReimbursementType !== 'travelExpensesNone' ? (
                        <>
                          <h5>{ t('application_form.sections.travel_expanses.title') }</h5>
                          <div className="row g-3">
                            {
                              travelReimbursementType === 'travelExpensesFlatRate' ? (
                                <>
                                  <div className="col-md-9 d-flex align-items-center">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" value={ true }
                                             checked={ values.travelExpensesFlatRate } id="checkboxTravelExpensesFlatRate"
                                             name="travelExpensesFlatRate" onChange={ handleChange }/>
                                      <label className="form-check-label" htmlFor="checkboxTravelExpensesFlatRate">
                                        { t('application_form.sections.travel_expanses.type.lump_sum') }
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="inputTravelExpensesFlatValue" className="form-label">{ t('application_form.sections.travel_expanses.value') }</label>
                                    <div className="input-group mb-3">
                                      <input type="number" className="form-control" id="inputTravelExpensesFlatValue" min="0"
                                             step="0.01" placeholder="0,00" name="travelExpensesFlatValue" onChange={ handleChange }
                                             value={ values.travelExpensesFlatValue } disabled={ !values.travelExpensesFlatRate }
                                             ariaDescribedby="currency-addon3"/>
                                      <span className="input-group-text" id="currency-addon3">{ values.currency }</span>
                                    </div>
                                  </div>
                                </>
                              ) : travelReimbursementType === 'travelExpensesDistanceBased' ? (
                                <>
                                  <div className="col-md-3 d-flex align-items-center">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" value={ true }
                                             checked={ values.travelExpensesDistanceBased } id="checkboxTravelExpensesDistanceBased"
                                             name="travelExpensesDistanceBased" onChange={ handleChange }/>
                                      <label className="form-check-label" htmlFor="checkboxTravelExpensesDistanceBased">
                                        { t('application_form.sections.travel_expanses.type.km_based') }
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="inputTravelExpensesDistance" className="form-label">{ t('application_form.sections.travel_expanses.distance') }</label>
                                    <div className="input-group mb-3">
                                      <input type="number" className="form-control" id="inputTravelExpensesDistance" placeholder="0"
                                             min="0" step="1" name="travelExpensesDistance" onChange={ handleChange }
                                             value={ values.travelExpensesDistance }
                                             disabled={ !values.travelExpensesDistanceBased }
                                             ariaDescribedby="distance-addon1"/>
                                      <span className="input-group-text" id="distance-addon1">KM</span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="inputTravelExpensesDistanceKmValue" className="form-label">{ t('application_form.sections.travel_expanses.value_per_km') }</label>
                                    <div className="input-group mb-3">
                                      <input type="number" className="form-control" id="inputTravelExpensesDistanceKmValue"
                                             min="0" step="0.01" name="travelExpensesDistanceKmValue" onChange={ handleChange }
                                             value={ values.travelExpensesDistanceKmValue }
                                             disabled
                                             placeholder="0.00"
                                             ariaDescribedby="currency-addon4"/>
                                      <span className="input-group-text" id="currency-addon4">{ values.currency }</span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="inputTravelExpensesDistanceCalculated" className="form-label">{ t('application_form.sections.travel_expanses.value') }</label>
                                    <div className="input-group mb-3">
                                      <input disabled type="number" className="form-control"
                                             id="inputTravelExpensesDistanceCalculated"
                                             step="0.01"
                                             value={ Math.round((values.travelExpensesDistance * values.travelExpensesDistanceKmValue || 0) * 100) / 100 }
                                             ariaDescribedby="currency-addon5"/>
                                      <span className="input-group-text" id="currency-addon5">{ values.currency }</span>
                                    </div>
                                  </div>
                                </>
                              ) : null
                            }

                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox"
                                       value={ true } checked={ values.travelExpensesAdditional }
                                       name="travelExpensesAdditional"
                                       onChange={ handleChange }
                                       id="checkboxTravelExpensesAdditional"/>
                                <label className="form-check-label" htmlFor="checkboxTravelExpensesAdditional">
                                  { t('application_form.sections.travel_expanses.type.additional_expanses') }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="inputTravelExpensesAdditionalValue" className="form-label">{ t('application_form.sections.travel_expanses.value') }</label>
                              <div className="input-group mb-3">
                                <input type="number" className="form-control" id="inputTravelExpensesAdditionalValue"
                                       placeholder="0" disabled={ !values.travelExpensesAdditional }
                                       min="0" step="0.01" name="travelExpensesAdditionalValue" onChange={ handleChange }
                                       value={ values.travelExpensesAdditionalValue }
                                       ariaDescribedby="currency-addon6"/>
                                <span className="input-group-text" id="currency-addon6">{ values.currency }</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null
                    }

                    {
                      formData.predefinedReimbursement ||
                      formData.showMedicalExpenses ||
                      formData.showPostalExpenses ||
                      formData.showCateringExpenses ||
                      formData.showOutfitExpenses ||
                      formData.showPrizeExpenses ||
                      formData.showAdditionalExpenses ? (
                        <h5>{ t('application_form.sections.additional_expanses.title') }</h5>
                      ) : null
                    }

                    <div className="row">
                      {
                        formData.predefinedReimbursement ? (
                          <>
                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="checkboxPredefinedReimbursement"
                                       value={ true }
                                       checked
                                       disabled
                                       name="predefinedReimbursement"/>
                                <label className="form-check-label" htmlFor="checkboxPredefinedReimbursement">
                                  { formData.predefinedReimbursement }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputPredefinedReimbursement"
                                name="predefinedReimbursementValue"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                value={values.predefinedReimbursementValue}
                                placeholder="0"
                                disabled
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }


                      {
                        formData.showMedicalExpenses ? (
                          <>
                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="checkboxAdditionalExpensesMed"
                                       value={ true }
                                       checked={ values.additionalExpensesMed }
                                       name="additionalExpensesMed" onChange={ handleChange }/>
                                <label className="form-check-label" htmlFor="checkboxAdditionalExpensesMed">
                                  { t('application_form.sections.additional_expanses.type.medical') }&nbsp;&nbsp;
                                  <Whisper placement="right" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
                                    <FontAwesomeIcon icon="fa-circle-info" />
                                  </Whisper>
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputAdditionalExpensesMed"
                                name="additionalExpensesMedValue"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                onChange={handleChange}
                                value={values.additionalExpensesMedValue}
                                placeholder="0"
                                disabled={ !values.additionalExpensesMed }
                                requiredField={!!values.additionalExpensesMed}
                                error={errors.additionalExpensesMedValue}
                                touched={touched.additionalExpensesMedValue}
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }

                      {
                        formData.showPostalExpenses ? (
                          <>
                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox"
                                       id="checkboxAdditionalExpensesPostage"
                                       value={ true }
                                       checked={ values.additionalExpensesPostage }
                                       name="additionalExpensesPostage" onChange={ handleChange }/>
                                <label className="form-check-label" htmlFor="checkboxAdditionalExpensesPostage">
                                  { t('application_form.sections.additional_expanses.type.postage') }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputAdditionalExpensesPostage"
                                name="additionalExpensesPostageValue"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                onChange={handleChange}
                                value={values.additionalExpensesPostageValue}
                                placeholder="0"
                                disabled={ !values.additionalExpensesPostage }
                                requiredField={!!values.additionalExpensesPostage}
                                error={errors.additionalExpensesPostageValue}
                                touched={touched.additionalExpensesPostageValue}
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }

                      {
                        formData.showCateringExpenses ? (
                          <>
                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox"
                                       id="checkboxAdditionalExpensesCatering"
                                       value={ true }
                                       checked={ values.additionalExpensesCatering }
                                       name="additionalExpensesCatering" onChange={ handleChange }/>
                                <label className="form-check-label" htmlFor="checkboxAdditionalExpensesCatering">
                                  { t('application_form.sections.additional_expanses.type.catering') }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputAdditionalExpensesCatering"
                                name="additionalExpensesCateringVal"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                onChange={handleChange}
                                value={values.additionalExpensesCateringVal}
                                placeholder="0"
                                disabled={ !values.additionalExpensesCatering }
                                requiredField={!!values.additionalExpensesCatering}
                                error={errors.additionalExpensesCateringVal}
                                touched={touched.additionalExpensesCateringVal}
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }

                      {
                        formData.showOutfitExpenses ? (
                          <>
                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox"
                                       id="checkboxAdditionalExpensesOutfit"
                                       value={ true }
                                       checked={ values.additionalExpensesOutfit }
                                       name="additionalExpensesOutfit" onChange={ handleChange }/>
                                <label className="form-check-label" htmlFor="checkboxAdditionalExpensesOutfit">
                                  { t('application_form.sections.additional_expanses.type.outfit') }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputAdditionalExpensesOutfit"
                                name="additionalExpensesOutfitValue"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                onChange={handleChange}
                                value={values.additionalExpensesOutfitValue}
                                placeholder="0"
                                disabled={ !values.additionalExpensesOutfit }
                                requiredField={!!values.additionalExpensesOutfit}
                                error={errors.additionalExpensesOutfitValue}
                                touched={touched.additionalExpensesOutfitValue}
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }

                      {
                        formData.showPrizeExpenses ? (
                          <>
                            <div className="col-md-9 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="checkboxAdditionalExpensesWin"
                                       value={ true }
                                       checked={ values.additionalExpensesWin }
                                       name="additionalExpensesWin" onChange={ handleChange }/>
                                <label className="form-check-label" htmlFor="checkboxAdditionalExpensesWin">
                                  { t('application_form.sections.additional_expanses.type.prize') }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputAdditionalExpensesWin"
                                name="additionalExpensesWinValue"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                onChange={handleChange}
                                value={values.additionalExpensesWinValue}
                                placeholder="0"
                                disabled={ !values.additionalExpensesWin }
                                requiredField={!!values.additionalExpensesWin}
                                error={errors.additionalExpensesWinValue}
                                touched={touched.additionalExpensesWinValue}
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }

                      {
                        formData.showAdditionalExpenses ? (
                          <>
                            <div className="col-md-9">
                              <label htmlFor="inputOtherExpenses" className="form-label">{ t('application_form.sections.additional_expanses.type.additional_expanses') }</label>
                              <input type="text" className="form-control" id="inputOtherExpenses"
                                     name="addExpensesOthExp" onChange={ handleChange }
                                     value={ values.addExpensesOthExp }/>
                            </div>
                            <div className="col-md-3">
                              <InputGroup
                                id="inputAddExpensesOthExpVal"
                                name="addExpensesOthExpVal"
                                label={ t('application_form.sections.additional_expanses.value') }
                                type="number"
                                onChange={handleChange}
                                value={values.addExpensesOthExpVal}
                                placeholder="0"
                                disabled={ !values.addExpensesOthExp }
                                requiredField={!!values.addExpensesOthExp}
                                error={errors.addExpensesOthExpVal}
                                touched={touched.addExpensesOthExpVal}
                                inputGroupText={values.currency} />
                            </div>
                          </>
                        ) : null
                      }

                    </div>
                  </section>

                  {
                    // BELEGUPLOAD
                  }
                  <section className="mb-5">
                    <h4>{ t('application_form.sections.doc_upload.title') }</h4>
                    <Uploader
                      action={`${API_BASE_URI}/${formCode}/files`}
                      accept={AllowedUploadFileFormats.join(',')}
                      listType="picture-text"
                      draggable
                      multiple
                      shouldUpload={(fileInfo) => {
                        /*console.log(fileInfo);
                        const fileParts = fileInfo.name.split('.');
                        const fileEnding = fileParts[Math.max(fileParts.length - 1, 0)];
                        if (AllowedUploadFileFormats.includes(`.${fileEnding.toLowerCase()}`)) {
                          return true;
                        }

                        return false;*/

                        return true;
                      }}
                      onPreview={(file, evt) => {
                        // File download
                        const files = values.files.filter((fileIn) => fileIn.fileKey === file.fileKey);
                        if (files.length > 0) {
                          window.open(`${API_BASE_URI}/${formCode}/clientData/uploads/${files[0].filename}`, '_blank');
                        }
                      }}
                      onRemove={(evt) => {
                        const fileToDelete = values.files.filter((file) => file.fileKey === evt.fileKey);
                        if (fileToDelete.length === 0) {
                          return;
                        }

                        setValues((prevValues) => ({
                          ...prevValues,
                          files: prevValues.files.filter((file) => file.fileKey !== evt.fileKey)
                        }), true);

                        ApiClient.delete(`/forms/${formCode}/files/${fileToDelete[0].filename}`);
                      }}
                      //onProgress={(percent, file) => {console.log(percent, file)}}
                      onSuccess={(response, file) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          files: [
                            ...prevValues.files,
                            {
                              ...response,
                              originalFileName: file.blobFile.name,
                              fileType: file.blobFile.type,
                              fileKey: file.fileKey
                            }
                          ]
                        }), true)
                      }}
                      onChange={(evt) => {console.log(evt)}}>
                      <div style={ { lineHeight: '150px' } }>{ t('application_form.sections.doc_upload.uploader_help_text') }</div>
                    </Uploader>
                  </section>

                  {
                    // BEDINGUNGEN (CHECKBOXEN)
                  }
                  <section>
                    <div className="col-md-12 d-flex align-items-center mb-3">
                      <CheckBox
                        id="checkboxCorrectDataConfirm"
                        name="correctDataConfirm"
                        label={ t('application_form.sections.conditions.correctness_of_data') }
                        checked={ values.correctDataConfirm }
                        value={ true }
                        onChange={ handleChange }
                        error={ errors.correctDataConfirm }
                        touched={ touched.correctDataConfirm }
                        onBlur={ handleBlur } />
                    </div>

                    <div className="col-md-12 d-flex align-items-center mb-3">
                      <CheckBox
                        id="checkboxDocsUplConfirm"
                        name="docsUplConfirm"
                        label={ t('application_form.sections.conditions.doc_upload_complete') }
                        checked={ values.docsUplConfirm }
                        value={ true }
                        onChange={ handleChange }
                        error={ errors.docsUplConfirm }
                        touched={ touched.docsUplConfirm }
                        onBlur={ handleBlur } />
                    </div>

                    <div className="col-md-12 d-flex align-items-center">
                      <CheckBox
                        id="checkboxPrivacyTermsConfirm"
                        name="privacyConfirm"
                        label={ <Trans i18nKey="application_form.sections.conditions.privacy_confirm" components={{ Link: <Link to="/privacy" target="_blank" /> }} /> }
                        checked={ !!values.privacyConfirm }
                        value={ new Date() }
                        onChange={ handleChange }
                        error={ errors.privacyConfirm }
                        touched={ touched.privacyConfirm }
                        onBlur={ handleBlur } />
                    </div>
                  </section>

                  {
                    errors.files || submissionError ? (
                      <section className="mt-3">
                        <div className="alert alert-danger" role="alert">
                          {
                            errors.files ? <span>{ errors.files }</span> : null
                          }
                          {
                            submissionError?.message ? <span>{ submissionError.message }</span> : null
                          }
                          {
                            submissionError?.details ? <><br /><span><small>{ submissionError.details }</small></span></> : null
                          }
                        </div>
                      </section>
                    ) : null
                  }

                  {
                    // SUBMIT
                  }
                  <section className="mb-5 d-flex justify-content-end">
                    <button type="submit" className="btn btn-dark" disabled={ isSubmitting }>
                      { t('application_form.submit') }
                    </button>
                  </section>
                </form>
              </div>
              <AppFooter/>
              <SumFooter payback={ expectedPayback } currency={ values.currency }/>
            </>
          );
        }
      }
    </Formik>
  );
}

export default ApplicationForm;
