function TextInput({ id, label, name, onChange, value, placeholder, error, touched, typeOverwrite, helpText, requiredField = false, ...rest }) {
  let validationClass = null;

  if (touched && error) {
    validationClass = 'is-invalid';
  } else if (touched && !error) {
    validationClass = 'is-valid';
  }

  return (
    <>
      <label htmlFor={ id } className="form-label">{ requiredField ? `${label}*` : label }</label>
      <input
        type={typeOverwrite || 'text'}
        className={ [ 'form-control', validationClass ].join(' ') }
        placeholder={ placeholder }
        id={ id }
        name={ name }
        onChange={ onChange }
        value={ value }
        {...rest}/>
      {
        error ? (
          <div className="invalid-feedback">
            { error }
          </div>
        ) : helpText ? (
          <div id={`${id}HelpText`} className="form-text">{helpText}</div>
        ) : null
      }
    </>
  );
}

export default TextInput;
