import * as Yup from 'yup';
import * as IBAN from 'iban';

const ApplicationFormValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Vorname wird benötigt'),
  lastName: Yup.string()
    .required('Nachname wird benötigt'),
  address: Yup.string()
    .required('Adresse wird benötigt'),
  zip: Yup.string()
    .min(4)
    .required('Postleitzahl wird benötigt'),
  city: Yup.string()
    .required('Ort wird benötigt'),
  country: Yup.string()
    .required('Land wird benötigt'),
  email: Yup.string()
    .email('E-Mail hat ein ungültiges Format')
    .required('E-Mail wird benötigt'),
  emailConfirmation: Yup.string()
    .email('E-Mail hat ein ungültiges Format')
    .required('E-Mail Bestätigung wird benötigt')
    .test(
      'email-confirmation-valid',
      'E-Mail Bestätigung falsch',
      (value, context) => value?.toLowerCase() === context.parent.email?.toLowerCase()
    ),
    //.oneOf([Yup.ref('email'), null], 'E-Mail Bestätigung falsch'),

  bankAccountOwner: Yup.string()
    .required('Kontoinhaber wird benötigt'),
  iban: Yup.string()
    .min(12)
    .test(
      'is-valid-iban',
      'IBAN ist nicht gültig',
      (value, context) => IBAN.isValid(value)
    )
    .required('IBAN wird benötigt'),
  bankInstitute: Yup.string()
    .optional('Bankinstitut wird benötigt'),
  swift: Yup.string()
    .matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'SWIFT Code hat ungültiges Format')
    .when('iban', {
      is: (iban) => iban && iban.length > 0 && !iban.startsWith('DE'),
      then: (schema) => schema
        .required('SWIFT Code/BIC wird benötigt'),
      otherwise: (schema) => schema
        .optional()
    }),

  performancePeriodFrom: Yup.date()
    .required('Leistungsdatum wird benötigt'),

  attendanceDaysCount: Yup.number()
    .when('attendance', {
      is: 'daily-base',
      then: (schema) => schema.min(1).required('Tage werden benötigt')
    }),

  attendanceDayValue: Yup.number()
    .when('attendance', {
      is: 'participationDayBased',
      then: (schema) => schema.min(0).required('Betrag wird benötigt')
    }),

  attendanceFlatRateSum: Yup.number()
    .when('attendance', {
      is: 'participationFixedRate',
      then: (schema) => schema.min(1).required('Tage werden benötigt')
    }),

  travelExpensesFlatValue: Yup.number()
    .when('travelExpensesFlatRate', {
      is: true,
      then: (schema) => schema.min(0).required('Betrag wird benötigt')
    }),

  additionalExpensesMedValue: Yup.number()
    .when('additionalExpensesMed', {
      is: true,
      then: (schema) => schema.min(0.01, 'Betrag größer 0 benötigt').required('Betrag wird benötigt')
    }),

  additionalExpensesPostageValue: Yup.number()
    .when('additionalExpensesPostage', {
      is: true,
      then: (schema) => schema.min(0.01, 'Betrag größer 0 benötigt').required('Betrag wird benötigt')
    }),

  additionalExpensesCateringVal: Yup.number()
    .when('additionalExpensesCatering', {
      is: true,
      then: (schema) => schema.min(0.01, 'Betrag größer 0 benötigt').required('Betrag wird benötigt')
    }),

  additionalExpensesOutfitValue: Yup.number()
    .when('additionalExpensesOutfit', {
      is: true,
      then: (schema) => schema.min(0.01, 'Betrag größer 0 benötigt').required('Betrag wird benötigt')
    }),

  additionalExpensesWinValue: Yup.number()
    .when('additionalExpensesWin', {
      is: true,
      then: (schema) => schema.min(0.01, 'Betrag größer 0 benötigt').required('Betrag wird benötigt')
    }),

  addExpensesOthExpVal: Yup.number()
    .when('addExpensesOthExp', {
      is: (value) => !!value,
      then: (schema) => schema.min(0.01, 'Betrag größer 0 benötigt').required('Betrag wird benötigt')
    }),

  files: Yup.array()
    .when('additionalExpensesMed', {
      is: true,
      then: (schema) => schema.min(1, 'Deine Angaben erfordern den Upload von Belegen.')
    })
    .when('additionalExpensesPostage', {
      is: true,
      then: (schema) => schema.min(1, 'Deine Angaben erfordern den Upload von Belegen.')
    })
    .when('additionalExpensesCatering', {
      is: true,
      then: (schema) => schema.min(1, 'Deine Angaben erfordern den Upload von Belegen.')
    })
    .when('additionalExpensesOutfit', {
      is: true,
      then: (schema) => schema.min(1, 'Deine Angaben erfordern den Upload von Belegen.')
    })
    .when('addExpensesOthExp', {
      is: (value) => !!value,
      then: (schema) => schema.min(1, 'Deine Angaben erfordern den Upload von Belegen.')
    })
    .when('travelExpensesAdditional', {
      is: (value) => !!value,
      then: (schema) => schema.min(1, 'Deine Angaben erfordern den Upload von Belegen.')
    }),

  correctDataConfirm: Yup.bool().oneOf([true], 'Die Bedingung muss akzeptiert werden.'),

  docsUplConfirm: Yup.bool().oneOf([true], 'Die Bedingung muss akzeptiert werden.'),

  privacyConfirm: Yup.date().nullable().required('Die Datenschutzerklärung muss akzeptiert werden.'),
});

export default ApplicationFormValidationSchema;
