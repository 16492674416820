import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppNav from '../../components/AppNav/AppNav';
import ApplicationForm from '../../components/ApplicationForm/ApplicationForm';

function FormDetailPage() {

  const params = useParams();
  const [ formData, setFormData ] = useState(null);

  useEffect(() => {
    const fetchFormData = async () => {

    }

    fetchFormData();
  }, []);

  return (
    <div>
      <AppNav />
      <div className="container">
        <h1>Formulardetails</h1>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <strong>Formular Code: { params.formCode }</strong>
              </div>
              <div className="card-body">
                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            {
              //<iframe className="w-100 h-100" src={`/${params.formCode}`} />
              //<ApplicationForm />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormDetailPage;
