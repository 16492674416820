function InputGroup({ id, label, name, onChange, value, placeholder, requiredField = false, error, touched, type, inputGroupText, ...rest }) {
  let validationClass = null;

  if (touched && error) {
    validationClass = 'is-invalid';
  } else if (touched && !error) {
    validationClass = 'is-valid';
  }

  return (
    <>
      <label htmlFor={ id } className="form-label">{ requiredField ? `${label}*` : label }</label>
      <div className="input-group mb-3">
        <input
          type={ type }
          className={ [ 'form-control', validationClass ].join(' ') }
          id={ id }
          placeholder={ placeholder }
          name={ name }
          aria-describedby={ `${ id }-addon` }
          value={ value }
          onChange={ onChange }
          { ...rest } />
        <span className="input-group-text" id={ `${ id }-addon` }>{ inputGroupText }</span>
        {
          error ? (
            <div className="invalid-feedback">
              { error }
            </div>
          ) : null
        }
      </div>
    </>
  );
}

export default InputGroup;
