import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import ApiClient from '../../utils/ApiClient';
import moment from 'moment-timezone';
import AppNav from '../../components/AppNav/AppNav';
import EmailLogEntryTypePill from '../../components/EmailLogEntryTypePill';
import { Link } from 'react-router-dom';

function EmailLogPage() {
  const { accounts, instance } = useMsal();
  const isManager = instance.getActiveAccount().idTokenClaims.roles.includes('Forms.Edit');

  const [ logEntries, setLogEntries ] = useState([]);
  const [ fetchingLogEntries, setFetchingLogEntries ] = useState([]);

  useEffect(() => {
    const fetchLogEntries = async () => {
      setFetchingLogEntries(true);
      try {
        const response = await ApiClient.get('/admin/logs/emailLog', {
          headers: {
            'Authorization': `Bearer ${(await instance.acquireTokenSilent({account: instance.getActiveAccount(), scopes: ["api://d5d22785-173e-47f9-b48c-4909fc3b9098/Api.Access"]})).accessToken}`
          }
        });

        setLogEntries(response.data);
      } catch (e) {

      } finally {
        setFetchingLogEntries(false);
      }
    };

    fetchLogEntries();
  }, []);

  return (
    <div>
      <AppNav />
      <div className="container">
        <h1>E-Mail Log</h1>

        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">Sendezeitpunkt</th>
            <th scope="col">Status</th>
            <th scope="col">Empfänger</th>
            <th scope="col">Betreff</th>
            <th scope="col">Details</th>
          </tr>
          </thead>
          <tbody>
          {
            fetchingLogEntries ? (
              <tr>
                <th scope="row" colSpan="5">
                  Lade Daten
                </th>
              </tr>
            ) : logEntries.length === 0 ? (
              <tr>
                <th scope="row" colSpan="5">
                  Keine Logs gefunden
                </th>
              </tr>
            ) : logEntries.map((logEntry) => (
              <tr key={logEntry.id}>
                <td>
                  { logEntry.appTimestamp ? moment.utc(logEntry.appTimestamp).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm:ss') : 'n/a' }
                </td>
                <td>
                  <EmailLogEntryTypePill logType={logEntry.logType} />
                </td>
                <td>
                  { logEntry.recipient }
                </td>
                <td>
                  { logEntry.subject }
                </td>
                <td>
                  <Link to={`/panel/logs/email/${logEntry.id}`} state={{ logEntry }}>Details</Link>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EmailLogPage;
