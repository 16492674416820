import AppNav from '../../components/AppNav/AppNav';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ApiClient from '../../utils/ApiClient';
import AppHeader from '../../components/AppHeader/AppHeader';

function PDFViewerPage({ pdfURL }) {
  const { formCode } = useParams();
  const { state } = useLocation();
  const [ formData, setFormData ] = useState(
    (state || {}).formData
  );
  const [ fetchingFormData, setFetchingFormData ] = useState(true);
  const [ fetchingPDF, setFetchingPDF] = useState(false);
  const [ pdfObjectURL, setPdfObjectURL] = useState(null);

  // TODO: ADAPT ERROR HANDLING
  // Use Mount Hook to fetch Form Data
  useEffect(() => {
    const getFormData = async () => {
      setFetchingFormData(true);
      try {
        const response = await ApiClient.get(`/forms/${formCode}`);
        setFormData(response.data);
        setFetchingFormData(false);
      } catch (e) {
        /*
        if (e.isAxiosError) {
          console.log(e)
          if (!e.response) {    // Backend nicht verfügbar
            navigate('/', {
              state: {
                error: 'ERROR_BACKEND_NOT_AVAILABLE',
                formCode
              },
              replace: true
            });
          } else if (e.response.data.statusCode === 404 && e.response.data.message === 'FORM_NOT_FOUND') {
            navigate('/', {
              state: {
                error: 'ERROR_FORM_NOT_FOUND',
                formCode
              },
              replace: true
            });
          } else {
            navigate('/', {
              state: {
                error: 'ERROR_FORM_DATA_FETCH_ERROR',
                formCode
              },
              replace: true
            });
          }
        } else {
          navigate('/', {
            state: {
              error: 'ERROR_UNDEFINED_REQUEST',
              formCode
            },
            replace: true
          });
        }
        */
      }
    };

    if (!formData) {
      getFormData();
    } else {
      setFetchingFormData(false);
    }
  }, []);

  useEffect(() => {

    if (formData) {
      const fetchPDF = async () => {
        setFetchingPDF(true);
        const response = await ApiClient.get('/pdfCreator/formPDF?c=j2lr2niq&h=https://localhost:3002&p=GNTM%202022', {
          responseType: 'blob'
        });

        setPdfObjectURL(window.URL.createObjectURL(response.data))

        setFetchingPDF(false);
      }

      fetchPDF();
    }

  }, [formData]);

  if (fetchingFormData) {
    return (
      <div>
        <AppNav />

        <div className="container text-center mt-4">
          <h3>
            LADE FORMULARDATEN
          </h3>
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (fetchingPDF) {
    return (
      <div>
        <AppNav />

        <div className="container text-center mt-4">
          <h3>
            LADE PDF
          </h3>
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <AppNav />

      <div className="container">
        <h1>PDF Viewer</h1>
        <iframe src={pdfObjectURL} className="w-100 h-100" title="W3Schools Free Online Web Tutorials"></iframe>
      </div>
    </div>
  );
}

export default PDFViewerPage;
