import { useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import AppNav from '../../components/AppNav/AppNav';
import { loginRequest } from '../../authConfig';
import { useEffect } from 'react';
import AppFooter from '../../components/AppFooter/AppFooter';

export default function LoginPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/panel', { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <div>
      <AppNav />
      <div className="container">
        <h2>Mitarbeiter Login</h2>
        {
          state ? (
            <div className="alert alert-primary" role="alert">
              { JSON.stringify(state) }
            </div>
          ) : null
        }
        <p>
          Hallo, bitte melde dich mit deinem P7S1 Windows Login an um auf die Anwendung zugreifen zu können.
        </p>
        <p>
          <button className="btn btn-danger" onClick={() => instance.loginRedirect(loginRequest)}>Anmelden mit P7S1 Login</button>
        </p>
      </div>
      <AppFooter />
    </div>
  );
}
