import { MaskedInput } from 'rsuite';

function MaskedTextInput({ id, label, name, onChange, value, placeholder, requiredField = false, error, touched, mask }) {
  let validationClass = null;

  if (touched && error) {
    validationClass = 'is-invalid';
  } else if (touched && !error) {
    validationClass = 'is-valid';
  }

  return (
    <>
      <label htmlFor={ id } className="form-label">{ requiredField ? `${label}*` : label }</label>
      <MaskedInput
        keepCharPositions={true}
        guide={true}
        placeholderChar={'\u2000'}
        mask={mask}
        name={name}
        id={id}
        value={ value }
        className={ [ 'form-control', validationClass ].join(' ') }
        onChange={ (value, evt) => { onChange(evt); } } />
      {
        error ? (
          <div className="invalid-feedback">
            { error }
          </div>
        ) : null
      }
    </>
  );
}

export default MaskedTextInput;
