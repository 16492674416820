function CheckBox({ id, label, name, onChange, value, checked, error, touched, ...rest }) {
  let validationClass = null;

  if (touched && error) {
    validationClass = 'is-invalid';
  } else if (touched && !error) {
    validationClass = 'is-valid';
  }

  return (
    <div className="form-check form-check-inline">
      <input type="checkbox"
             className={ [ 'form-check-input', validationClass ].join(' ') }
             id={ id }
             value={ value }
             checked={ checked }
             name={ name }
             onChange={ onChange }/>
      <label className="form-check-label" htmlFor={ id }>
        { label }
      </label>
      {
        error ? (
          <div className="invalid-feedback">
            { error }
          </div>
        ) : null
      }
    </div>
  );
}

export default CheckBox;
