import * as Yup from 'yup';

const SupportFormValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Vorname wird benötigt'),
  lastName: Yup.string()
    .required('Nachname wird benötigt'),
  email: Yup.string()
    .email('E-Mail hat ein ungültiges Format')
    .required('E-Mail wird benötigt'),
  message: Yup.string()
    .required('Nachricht wird benötigt'),
});

export default SupportFormValidationSchema;
