import logo from '../../assets/red7-logo.png';
import './AppHeader.scss';

function AppHeader() {
  return (
    <header className="app-header mb-4 d-flex align-items-center">
      <div className="container">
        <img src={ logo } height="60" alt="RedSeven Logo" />
      </div>
    </header>
  );
}

export default AppHeader;
