import red7Logo from '../../assets/red7-logo.png';
import { Link, NavLink } from 'react-router-dom';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';

function AppNav() {
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {
        process.env.REACT_APP_BUILD_MODE !== 'production' ? (
        <div style={{lineHeight: '2rem'}} className="text-center bg-info text-white">
          Build: { process.env.REACT_APP_BUILD_MODE }
        </div>
        ) : null
      }
      <nav className="navbar navbar-expand-lg navbar-dark bg-black">
        <div className="container-fluid">
          <Link to={isAuthenticated ? '/panel' : '/'} className="navbar-brand">
            <img src={red7Logo} alt="RedSeven Entertainment Logo" height="60" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <AuthenticatedTemplate>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="/panel/forms" end className="nav-link">Formulare</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/panel/forms/new" className="nav-link">Neues Formular</NavLink>
                </li>
                <AuthenticatedTemplate>
                  <li className="nav-item">
                    <NavLink to="/panel/logs/email" className="nav-link">E-Mail Log</NavLink>
                  </li>
                </AuthenticatedTemplate>
              </ul>
              <AuthenticatedTemplate>
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="navbar-text text-end">
                    <small>
                      { accounts.length > 0 ? accounts[0].name : null }<br/>
                      <button className="btn btn-sm btn-link" onClick={() => instance.logoutRedirect({
                        postLogoutRedirectUri: '/'
                      })}>Abmelden</button>
                    </small>
                  </li>
                </ul>
              </AuthenticatedTemplate>
            </div>
          </AuthenticatedTemplate>
        </div>
      </nav>
    </>
  );
}

export default AppNav;
