import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

function AppFooter() {
  return (
    <footer>
      <div className="container text-center">
        <small>
          <Trans i18nKey="footer.address" />
        </small><br/>
        <small>
          <Link to="/privacy">
            <Trans i18nKey="footer.data_protection_terms" />
          </Link> &nbsp;·&nbsp;
          <a href="https://www.redseven.de/impressum">
            <Trans i18nKey="footer.imprint" />
          </a>
        </small><br />
        <small className="text-muted">
          RedSeven Aufwandserstattung WebApp v{ process.env.REACT_APP_VERSION }
        </small>
      </div>
    </footer>
  );
}

export default AppFooter;
